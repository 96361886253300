<template>
  <div class="content_container request">
    <div class="top_section">
      <div class="inner">

        <div class="top_btn_wrap">
            <div class="top_btn" @click="this.$router.push('/request/sourcing')">
                <div class="icon_wrap">
                    <img
                    src="@/assets/images/main/icon_tab_03.svg"
                    alt="상품소싱요청"
                    />
                </div>
                <div class="text">
                    <h1>상품소싱요청</h1>
                    <p>
                        찾으시는 상품이 없으시면<br /> 메타바이셀 관리자에게 상품을
                        요청하세요.
                    </p>
                </div>
            </div>
            <div class="top_btn" @click="this.$router.push('/request/consulting')">
                <div class="icon_wrap">
                    <img src="@/assets/images/main/icon_tab_04.svg" alt="컨설팅" />
                </div>
                <div class="text">
                    <h1>거래중개 컨설팅</h1>
                    <p>
                        거래 진행에 어려움을 겪고 계시다면<br /> 전문 컨설턴트에게
                        문의하세요.
                    </p>
                </div>
            </div>
            <div class="top_btn" @click="this.$router.push('/request/inquire')">
                <div class="icon_wrap">
                    <img src="@/assets/images/common/icon_request.svg" alt="일반문의" />
                </div>
                <div class="text">
                    <h1>일반 문의</h1>
                    <p>
                        서비스를 이용하시면서 궁금하신 점이나<br /> 불편사항이 있으시다면
                        빠르게 처리해드립니다.
                    </p>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="bottom_section">
        <div class="search_filter">
            <div class="fill_select">
                <select v-model="filter">
                    <option value="전체">전체</option>
                    <option value="내문의">내문의</option>
                    <option value="상품소싱">상품소싱</option>
                    <option value="거래중개">거래중개</option>
                    <option value="일반문의">일반문의</option>
                </select>
            </div>
            <div class="search_wrapper">
                <input type="text"  placeholder="제목을 입력 후 Enter를 눌러주세요"
                    :value="searchInput"
                    @input="searchInput = $event.target.value"
                    @keyup.enter="search"
                />
                <button type="button" class="btn_search" @click="search"></button>
            </div>
        </div>
        
        <div class="common_table">
            <ul class="top">
                <li class="w60">No.</li>
                <li class="w120">문의유형</li>
                <li class="product">제목 / 상품명</li>
                <li class="w60">공개여부</li>
                <li class="w60">상태</li>
                <li class="w130">등록일</li>
                <li class="w60">보기</li>
            </ul>
            <ul class="content"
                v-for="(item, i) in this.sliceList"
                :key="i"
            >
                <li class="w60 rank">{{ this.searchList.length - this.maxShowList*(currentPage-1) - i }}</li>
                <li class="w120 sub"><p>{{ item.division }}</p></li>
                <li class="product">
                    <p>{{ item.title }}</p>
                </li>
                <li class="w60 sub"><p>{{ item.exposure_yn == 'true' ? '공개' : '비공개' }}</p></li>
                <li class="w60 sub"><p>{{ item.state }}</p></li>
                <li class="w130 sub mobile_hidden"><p>{{ this.dateFormat(item.enrollment_date) }}</p></li>
                <li class="w60 icon" @click="detailClicked(item)">
                    <img src="@/assets/images/common/search.svg" alt="돋보기" />
                </li>
            </ul>
            <ul class="default" v-if="this.searchList.length == 0">
                <li>공개/등록된 문의가 없습니다.</li>
            </ul>
            <BoardPagination
                :listLength="this.searchList.length"
                :maxShowList="maxShowList"
                :maxShowPage="maxShowPage"
                :currentPage="currentPage"
                @setPage="setPage"
            />
        </div>

      <!-- <div class="box_page_number" >
                <a href="javascript:void(0)"><span class="gt">&lt;</span></a>
                <a href="javascript:void(0)" class="this_page">1</a>
                <a href="javascript:void(0)"><span class="gt">></span></a>
            </div> -->
    </div>
  </div>
</template>

<script>
import BoardPagination from '@/components/BoardPagination'
import { fetchData } from "@/utils/fetchData";
import { dateFormat } from "@/utils/dateFormat";

export default {
  components : {
    BoardPagination,
  },
  computed: {
    sliceList(){
      return this.searchList.length == 0 ? [] : this.searchList.slice( (this.currentPage-1)*this.maxShowList, this.currentPage*this.maxShowList );
    },
  },
  watch: {
        // 검색 입력값 변경시 동작
        searchInput(search) {
            if (search == "") {
                // searchList 초기화
                this.currentPage = 1;
                this.searchList = this.filterList;
            }
        },
        filter(newVal){
            if(newVal == '전체'){
                this.filterList = this.list;
            } else if (newVal == '상품소싱') {
                this.filterList = this.list.filter(
                    item => item.division == '상품소싱'
                );
            } else if (newVal == '거래중개'){
                this.filterList = this.list.filter(
                    item => item.division == '거래중개 컨설팅'
                );
            } else if (newVal == '일반문의') {
                this.filterList = this.list.filter(
                    item => item.division == '일반문의'
                );
            } else if (newVal == '내문의') {
                this.filterList = this.list.filter(
                    item => item.self_yn == 'true'
                );
            }
            this.searchInput = '';
            this.currentPage = 1;
            this.searchList = this.filterList;
        }
  },
  mounted() {
    this.fetchInquireData();
  },
  data() {
    return {
      list: [], // 전체 리스트

      searchInput : '', // 검색 입력값
      searchList: [],   // 검색 후 리스트

      filter: '전체',   // filter
      filterList: [],   // 검색 후 리스트

      currentPage : 1,    // 현재 페이지
      maxShowList : 10,   // 한 페이지 보일 최대 리스트수
      maxShowPage : 5,    // 한 화면에 보일 최대 페이지수
    };
  },
  mixins: [fetchData, dateFormat],
  methods: {
    fetchInquireData() {
      this.fetchData(
        "/getInquire",
        (data) => {
            this.list = data.list;
            // this.list = this.list.filter(el => el.exposure_yn === 'true');
            // this.list = this.list.filter(el => !(el.state === '대기' && el.self_yn === 'false') );
            this.filterList = this.list;
            this.searchList = this.filterList;
        },
      );
    },
    // 검색
    search() {
        this.currentPage = 1;
        if (this.searchInput == "") {
            return;
        } else {
            this.searchList = this.filterList;
            const searchWords = this.searchInput.split(" ");

            searchWords.forEach((word) => {
                this.searchList = this.searchList.filter(
                    item => item.title.toLowerCase().includes(word.toLowerCase())// 제목 일치여부
                );
            });
        }
    },
    detailClicked(item){
        if(item.exposure_yn == 'false' && item.self_yn == 'false'){
            this.$store.commit('alert', '비공개 문의입니다.')
        } else {
            this.$router.push(`/request/detail/${item.inquire_pk}`)
        }
    },
    // 페이지 변경
    setPage(num){
        this.currentPage = num;
    },
  },
};
</script>

<style scoped src="@/assets/css/layout_request.css">
</style>